import { TransactionFetcher } from '@pendle/sdk'
import networkEnv from '@/app-config/networks'
import normalizeTransactions from '@/utils/normalize-transactions'
import PendleAmmMarket from '@/domains/entities/PendleAmmMarket'
import TransactionCollection from '@/domains/collections/TransactionCollection'

const fetcher = new TransactionFetcher(networkEnv.VUE_APP_NETWORK_ID)

export default class Transaction {
  constructor({ action, hash, user, amount, paid, received, network, protocol }) {
    this.action = action
    this.hash = hash
    this.user = user
    this.amount = amount
    this.paid = paid
    this.received = received
    this.network = network
    this.protocol = protocol
  }

  static getMintTransactions(forgeQuery) {
    return fetcher
      .getMintTransactions(forgeQuery)
      .then(
        (mintYieldTokens) =>
          new TransactionCollection.from(normalizeTransactions({ mintYieldTokens }))
      )
  }

  static getRedeemTransactions(forgeQuery) {
    return fetcher
      .getRedeemTransactions(forgeQuery)
      .then(
        (redeemYieldTokens) =>
          new TransactionCollection.from(normalizeTransactions({ redeemYieldTokens }))
      )
  }

  static getSwapTransactions(pendleAmmQuery) {
    return fetcher
      .getSwapTransactions(pendleAmmQuery)
      .then((swaps) => new TransactionCollection.from(normalizeTransactions({ swaps })))
  }

  static getLiquidityTransactions(pendleAmmQuery) {
    return fetcher
      .getLiquidityTransactions(pendleAmmQuery)
      .then(
        (liquidityPools) =>
          new TransactionCollection.from(normalizeTransactions({ liquidityPools }))
      )
  }

  static getAllTransactions(marketAddress) {
    const market = PendleAmmMarket.query().find(marketAddress)

    const { tokenIssuer, expiryEpoch } = market
    const { underlyingTokenAddress, forgeId } = tokenIssuer

    const forgeQuery = {
      forgeId,
      expiry: expiryEpoch,
      underlyingTokenAddress,
      page: 1,
      limit: 20,
    }

    const pendleAmmQuery = {
      marketAddress,
      page: 1,
      limit: 20,
    }

    return Promise.all([
      this.getMintTransactions(forgeQuery),
      this.getRedeemTransactions(forgeQuery),
      this.getSwapTransactions(pendleAmmQuery),
      this.getLiquidityTransactions(pendleAmmQuery),
    ]).then((responses) => responses.flat().sort((a, b) => b.timestamp - a.timestamp))
  }
}
