import TokenAmount from '@/domains/entities/TokenAmount'

const transactionMapping = {
  mintYieldTokens: {
    additionalData: () => ({
      filter: 'Mints/Redeems',
    }),
  },
  redeemYieldTokens: {
    additionalData: () => ({
      filter: 'Mints/Redeems',
    }),
  },
  liquidityPools: {
    additionalData: (txn) => ({
      filter: 'Liquidity',
      action: txn.action === 'Join' ? 'Add' : 'Remove',
    }),
  },
  swaps: {
    additionalData: () => ({
      filter: 'Swaps',
    }),
  },
}

export const normalizeTransactions = (allTransactions) => {
  const mapping = transactionMapping

  return Object.keys(mapping)
    .map((transactionType) => {
      const transactions = allTransactions[transactionType] || []

      return transactions.map((transaction) => ({
        ...transaction,
        ...mapping[transactionType].additionalData(transaction),
        type: transactionType,
      }))
    })
    .flat()
    .map(({ amount, ...txn }) => ({
      ...txn,
      id: `${txn.hash}-${txn.action}`,
      timestamp: parseInt(txn.timestamp),
      valuation: amount,
      paid: txn.paid.map((tokenAmount) => TokenAmount.fromSdkTokenAmount(tokenAmount)),
      received: txn.received.map((tokenAmount) => TokenAmount.fromSdkTokenAmount(tokenAmount)),
    }))
}

export default normalizeTransactions
